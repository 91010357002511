import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import CreateHistory from "./CreateHistory";
import UpdateHistory from "./UpdateHistory";
import CustomBodyRenderLiteInner from "../../../components/Datatables/CustomBodyRenderLiteInner";
import CustomToolbar from "../../../components/Datatables/CustomToolbar";
import textLabels from "../../../components/textLabels";

const useStyles = makeStyles(() => ({
  layout: {
    width: '100%',
  },
}));

function History({input, handleChange}) {
  const classes = useStyles();
  const data = input.records.filter(record => record.type === 'History');

  const columns = [
    { name: 'line', label: 'Название' },
    { name: 'value', label: 'Значение' },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: CustomBodyRenderLiteInner({
          data,
          Update: UpdateHistory,
          handleSuccess: (updateData, cb) => {
            handleChange({target: {name: 'records', value: input.records.map(item => item.id === updateData.id ? updateData : item )}});
            cb()
          }
        })
      }
    },
  ]
  const base = {
    line: "",
    value: "",
    hasValue: true,
    type: 'History',
  }
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'simple',
    onRowsDelete: (rowsDeleted, _newTableData) => {
      const indexes = rowsDeleted.data.map(index => index.dataIndex)
      const ids = data.filter((_item, key) => indexes.includes(key)).map(item => item.id)
      handleChange({target: {name: 'records', value: input.records.filter(item => !ids.includes(item.id) )}});
    },
    customToolbar: () => (
      <CustomToolbar base={base} Create={CreateHistory} handleSuccess={(newData, cb) => {
        handleChange({target: {name: 'records', value: [...input.records, newData]}});
        cb()
      }} />
    ),
    textLabels
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
      </main>
    </React.Fragment>
  );

}
export default History

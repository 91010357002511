import client from './client';
import {showError} from '../actions/error';
import {showLoader, hideLoader} from '../actions/loader';

export function fetchGenerator(type, action, method) {
    const init = () => ({type: `${type.toUpperCase()}_INIT`})
    const success = payload => ({type: `${type.toUpperCase()}_SUCCESS`, payload})
    const fail = () => ({type: `${type.toUpperCase()}_FAIL`})
    return (data, params, callback) => async dispatch => {
      try {
        dispatch(showLoader())
        dispatch(init())
        const response = await client.fetch(method, action, data, params)
        if (response.data.success === false) {
          dispatch(fail())
          dispatch(showError(response.data.data))
        } else {
          dispatch(success(response.data.data))
          if (callback) {
            callback(response.data.data)
          }
        }
      } catch (error) {
        dispatch(fail())
        dispatch(showError(error))
      }
      dispatch(hideLoader())
    };
}
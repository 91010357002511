import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import uuid from '../../../components/uuid'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

function CreateHomeCare({base, open, handleClose, handleSuccess}) {
    const [input, setInput] = useState(base)
    const handleSave = () => {
      handleSuccess({...input, id: uuid()})
      setInput(base)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    const handleDateChange = (name) => (value) => {
      handleChange({target: {name, value}});
    };
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoFocus
              margin="dense"
              id="date"
              name="date"
              label="Дата"
              format="yyyy.MM.dd"
              value={input['date']}
              onChange={handleDateChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'Дата',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            margin="dense"
            id="name"
            name="name"
            label="Название препаратов"
            type="text"
            fullWidth
            value={input['name']}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default CreateHomeCare  
import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";

function CustomToolbar({base, Create, handleSuccess}) {
    const [open, setOpen] = React.useState(false);
  
    return (
      <>
        <Create base={base} open={open} handleSuccess={data => handleSuccess(data, () => setOpen(false))} handleClose={() => setOpen(false)} />
        <IconButton
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddCircleIcon/>
        </IconButton>
      </>
    )
}
export default CustomToolbar  
import React from "react";
import { useSelector } from 'react-redux'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

function Questionnaire({input, handleChange}) {
    const filialsObj = useSelector(state => state.admin_entries.filials);
    const filials = Object.keys(filialsObj).map(key => filialsObj[key])
    const handleDateChange = (name) => (value) => {
      handleChange({target: {name, value}});
    };
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextField
            autoFocus
            margin="dense"
            id="questionnaire.surname"
            name="questionnaire.surname"
            label="Фамилия"
            type="text"
            fullWidth
            value={input['questionnaire.surname']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.name"
            name="questionnaire.name"
            label="Имя"
            type="text"
            fullWidth
            value={input['questionnaire.name']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.patronymic"
            name="questionnaire.patronymic"
            label="Отчество"
            type="text"
            fullWidth
            value={input['questionnaire.patronymic']}
            onChange={handleChange}
          />
          <KeyboardDatePicker
            margin="dense"
            id="questionnaire.birthDate"
            name="questionnaire.birthDate"
            label="Дата рождения"
            format="yyyy.MM.dd"
            value={input['questionnaire.birthDate']}
            onChange={handleDateChange('questionnaire.birthDate')}
            KeyboardButtonProps={{
              'aria-label': 'Дата рождения',
            }}
          />
          <InputLabel id="filial-select-label">Пол</InputLabel>
          <Select
            labelId="filial-select-label"
            margin="dense"
            id="questionnaire.sex"
            name="questionnaire.sex"
            fullWidth
            value={input['questionnaire.sex']}
            onChange={handleChange}
          >
            <MenuItem key='Male' value='Male'>Мужской</MenuItem>
            <MenuItem key='Female' value='Female'>Женский</MenuItem>
          </Select>
          <KeyboardDatePicker
            margin="dense"
            id="questionnaire.registrationDate"
            name="questionnaire.registrationDate"
            label="Дата"
            format="yyyy.MM.dd"
            value={input['questionnaire.registrationDate']}
            onChange={handleDateChange('questionnaire.registrationDate')}
            KeyboardButtonProps={{
              'aria-label': 'Дата рождения',
            }}
          />
          <TextField
            margin="dense"
            id="questionnaire.occupation"
            name="questionnaire.occupation"
            label="Род деятельности (учеба/работа)"
            type="text"
            fullWidth
            value={input['questionnaire.occupation']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.telephone"
            name="questionnaire.telephone"
            label="Телефон"
            type="text"
            fullWidth
            value={input['questionnaire.telephone']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.mail"
            name="questionnaire.mail"
            label="Электронная почта"
            type="text"
            fullWidth
            value={input['questionnaire.mail']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.individualCharacteristics"
            name="questionnaire.individualCharacteristics"
            label="Индивидуальные особенности клиента"
            type="text"
            fullWidth
            value={input['questionnaire.individualCharacteristics']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="questionnaire.source"
            name="questionnaire.source"
            label="Откуда о нас узнали"
            type="text"
            fullWidth
            value={input['questionnaire.source']}
            onChange={handleChange}
          />
  
          <InputLabel id="filial-select-label">Филиал</InputLabel>
          <Select
            labelId="filial-select-label"
            margin="dense"
            id="filialId"
            name="filialId"
            label=""
            fullWidth
            value={input.filialId}
            onChange={handleChange}
          >
            {
              filials.map((filial) => (
                <MenuItem key={filial.id} value={filial.id}>{filial.name}</MenuItem>
              ))
            }
          </Select>
        </MuiPickersUtilsProvider>
      </>
    )
}
export default Questionnaire
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";

function Render ({ name, entry_name, dataIndex, Update, handleSuccess }) {
    const entities = useSelector(state => state.admin_entries[entry_name]);
    const data = useSelector(state => state[name].data);
    const [open, setOpen] = React.useState(false);
    return (
        <>
            {open && (<Update raw={entities[data[dataIndex]] || {}} open={open} handleSuccess={data => handleSuccess(data, () => setOpen(false))} handleClose={() => setOpen(false)} />)}
            <IconButton
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <EditIcon/>
            </IconButton>
        </>
    );
}

function CustomBodyRenderLite(props) {
    return dataIndex => (
        <Render dataIndex={dataIndex} {...props} />
    )
}

export default CustomBodyRenderLite;

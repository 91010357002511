import { 
  SHOW_ERROR,
  LOGOUT,
  HIDE_ERROR
} from '../actions/types';

const initialState = {
  error: null
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return { ...state, error: [action.error.toString()] }
    case HIDE_ERROR:
    case LOGOUT:
      return { ...state, error: null }
    default:
      return state;
  }
}
import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import flattenObject from "../../../components/flattenObject";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

function UpdateClientHistory({raw, open, handleClose, handleSuccess}) {
    const clone = Object.assign({}, flattenObject(raw));
    delete clone.created_at
    delete clone.updated_at
    const [input, setInput] = useState(clone)

    const handleSave = () => {
      handleSuccess(input)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Изменить</DialogTitle>
        <DialogContent>
        <TextField
            margin="dense"
            id="line"
            name="line"
            label="Название"
            type="text"
            fullWidth
            value={input['line']}
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="value"
            name="value"
            label="Значение"
            type="text"
            fullWidth
            value={input['value']}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
             Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default UpdateClientHistory

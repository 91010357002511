import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createAdminCities } from '../../../actions'

function Create({base, open, handleClose, handleSuccess}) {
    const dispatch = useDispatch();
    const [input, setInput] = useState(base)
    const loader = useSelector(state => state.loader.loader);
    const handleSave = () => {
      dispatch(createAdminCities(input, {}, handleSuccess));
      setInput(base)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Город"
            type="text"
            fullWidth
            value={input.name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loader}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default Create  
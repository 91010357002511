import React, {useEffect, useState} from "react";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import {useDropzone} from 'react-dropzone';
import FormData from 'form-data';
import axios from 'axios';
import store from '../../../store';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

function Previews({handleChange, input}) {
  const maxSize = 5_242_880
  const {getRootProps, getInputProps, isDragReject, rejectedFiles} = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 20,
    minSize: 1,
    maxSize,
    onDrop: async acceptedFiles => {
      const form = new FormData();
      acceptedFiles.forEach(file => {
        form.append('images[]', file);
      })
      const response = await axios.post('/api/admin/clients/upload', form, {
        headers: {
          "Authorization": `Bearer ${ store.getState().auth.token.accessToken }`,
          "Accept": "application/json",
        }
      })
      handleChange({target: {name: 'photos', value: [...input.photos, ...response.data.data.map(file => ({file: file.filename}))]}});
    }
  });
  const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
          
  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Перетащите фотографии сюда или нажмите, чтобы выбрать их</p>
        {isDragReject && (
          <div className="text-danger mt-2">
            File type not accepted, sorry!
          </div>
        )}
        {isFileTooLarge && (
          <div className="text-danger mt-2">
            File is too large.
          </div>
        )}
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
    imageList: {
      width: 500,
      height: 450,
    },
}));
  
function Photos({input, handleChange}) {
    const classes = useStyles();
    return (
      <>
        <Previews input={input} handleChange={handleChange} />
        <ImageList rowHeight={160} className={classes.imageList} cols={3}>
          {input.photos.map((item) => (
            <ImageListItem key={item.file} cols={1}>
              <img src={`/api/admin/clients/files/${item.file}`} alt='' />
              {item.file}
            </ImageListItem>
          ))}
        </ImageList>
      </>
    )
}
export default Photos
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import MUIDataTable from "mui-datatables";

import withMainLayout from "../../../layouts/main";
import { fetchAdminUsers } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '100%',
  },
}));

const columns = [
  { name: 'username', label: 'Username' },
  { name: 'created_at', label: 'Создан' },
]

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loader = useSelector(state => state.loader.loader);
  const error = useSelector(state => state.error.error);
  const entities = useSelector(state => state.admin_entries.users);
  const data = useSelector(state => state.admin_users.data);
  const page = useSelector(state => state.admin_users.page);
  const perPage = useSelector(state => state.admin_users.perPage);
  const total = useSelector(state => state.admin_users.total);

  const [sorting, setSorting] = useState({sort: 'desc',sortBy: 'created_at'}) 

  const handleChangePage = (newPage) => {
    dispatch(fetchAdminUsers({sorting, paging: {perPage, page: newPage + 1}}));
  };
  const handleChangePerPage = (newPerPage) => {
    dispatch(fetchAdminUsers({sorting, paging: {perPage: newPerPage, page: 1}}));
  };
  const handleChangeSorting = (changedColumn, direction) => {
    setSorting({
      sort: direction,
      sortBy: changedColumn,
    })
    dispatch(fetchAdminUsers({sorting: {
      sort: direction,
      sortBy: changedColumn,
    }, paging: {perPage, page: 1}}));
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: 'simple',
    serverSide: true,
    count: total,
    page: page - 1,
    onChangeRowsPerPage: handleChangePerPage,
    onChangePage: handleChangePage,
    onColumnSortChange: (changedColumn, direction) => {
      handleChangeSorting(changedColumn, direction)
    },
  };
  

  useEffect(() => {
    dispatch(fetchAdminUsers());
  }, [dispatch]);
  
  if (!data) {
    return 
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <MUIDataTable
            title={<Typography component="h1" variant="h4" align="center">
              Пользователи
            </Typography>}
            data={data.map(id => entities[id])}
            columns={columns}
            options={options}
          />
          {loader && <LinearProgress size={24} />}
          {error && <Alert elevation={6} variant="filled" severity="error">{error}</Alert>}
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withMainLayout(Items);

import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useLocation } from 'react-router-dom'

export function ListItemLink({ disabled = false, icon, primary, to }) {
    const location = useLocation()
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
      [to],
    )
  
    return (
      <ListItem
        button
        selected={to === location.pathname}
        disabled={disabled ?? false}
        component={renderLink}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    )
  }
  
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createAdminFilials } from '../../../actions'

function Create({base, open, handleClose, handleSuccess}) {
    const dispatch = useDispatch();
    const [input, setInput] = useState(base)
    const loader = useSelector(state => state.loader.loader);
    const citiesObj = useSelector(state => state.admin_entries.cities);
    const cities = Object.keys(citiesObj).map(key => citiesObj[key])
    const handleSave = () => {
      dispatch(createAdminFilials(input, {}, handleSuccess));
      setInput(base)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Филиал"
            type="text"
            fullWidth
            value={input.name}
            onChange={handleChange}
          />
          <InputLabel id="city-select-label">Город</InputLabel>
          <Select
            labelId="city-select-label"
            margin="dense"
            id="cityId"
            name="cityId"
            fullWidth
            value={input.cityId}
            onChange={handleChange}
          >
            {
              cities.map((city) => (
                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
              ))
            }
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loader}>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default Create  
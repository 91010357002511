import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

import SignIn from "../pages/SignIn";
import Logout from "../pages/Logout";
import SignUp from "../pages/SignUp";
import AdminUsersItems from "../pages/Admin/Users/Items";
import AdminClientsItems from "../pages/Admin/Clients/Items";
import AdminFilialsItems from "../pages/Admin/Filials/Items";
import AdminCitiesItems from "../pages/Admin/Cities/Items";

const Router = () => {
  return (
    <>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/signup" component={SignUp} />

      <PrivateRoute exact path="/admin/users" component={AdminUsersItems} />
      <PrivateRoute exact path="/admin/clients" component={AdminClientsItems} />
      <PrivateRoute exact path="/admin/filials" component={AdminFilialsItems} />
      <PrivateRoute exact path="/admin/cities" component={AdminCitiesItems} />
    </>
  );
}
export default Router;

import { ADMIN_CITIES_SUCCESS } from '../../actions/types';

const initialState = {
  entities: {},
  data: [],
  perPage: 10,
  page: 1,
  total: 0,
}

export default function citiesReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_CITIES_SUCCESS:
      return { ...state,
        data: action.payload.items.map(item => item.id),
        perPage: action.payload.perPage,
        page: action.payload.page,
        total: action.payload.total }
    default:
      return state;
  }
}
import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ListItemLink } from '../ListItemLink'

export default () => {
  return (
    <>
      <ListItemLink
        icon={<PeopleIcon/>}
        primary='Клиенты'
        to='/admin/clients'
      />
      <ListItemLink
        icon={<LocationCityIcon/>}
        primary='Города'
        to='/admin/cities'
      />
      <ListItemLink
        icon={<BusinessIcon/>}
        primary='Филиалы'
        to='/admin/filials'
      />
      <ListItemLink
        icon={<ExitToAppIcon/>}
        primary='Выход'
        to="/logout"
      />
    </>
  );
}
import { 
    SHOW_ERROR,
    HIDE_ERROR,
} from './types';

const hideWithTimeout = action => {
    return dispatch => {
        dispatch(action)
        setTimeout(() => {
            dispatch(hideError())
        }, 3000)
    }
}

export function showError(error) {
    return hideWithTimeout({
        type: SHOW_ERROR,
        error
    })
}
export function hideError() {
    return {
        type: HIDE_ERROR
    }
}
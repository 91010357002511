export default {
    body: {
        noMatch: "Нет записей",
        toolTip: "Сортировка",
        columnHeaderTooltip: column => `Поиск ${column.label}`
    },
    pagination: {
        next: "Следующая страница",
        previous: "Предыдущая страница",
        rowsPerPage: "Записей на странице:",
        displayRows: "из",
    },
    toolbar: {
        search: "Поиск",
        downloadCsv: "Скачать CSV",
        print: "Распечатать",
        viewColumns: "Колонки",
        filterTable: "Фильтр",
    },
    filter: {
        all: "Все",
        title: "Фильтр",
        reset: "Очистить",
    },
    viewColumns: {
        title: "Показать колонки",
        titleAria: "Показать/скрыть",
    },
    selectedRows: {
        text: "строк выбрано",
        delete: "Удалить",
        deleteAria: "Удалить выбранные записи",
    },
}
import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import uuid from '../../../components/uuid'


function CreateClientHistory({base, open, handleClose, handleSuccess}) {
    const [input, setInput] = useState(base)
    const handleSave = () => {
      handleSuccess({...input, id: uuid()})
      setInput(base)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="line"
            name="line"
            label="Название"
            type="text"
            fullWidth
            value={input['line']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="value"
            name="value"
            label="Значение"
            type="text"
            fullWidth
            value={input['value']}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default CreateClientHistory  
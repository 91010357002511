export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_INIT = 'LOGIN_INIT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const SIGN_UP_INIT = 'SIGN_UP_INIT';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const REFRESH_INIT = 'REFRESH_INIT';
export const LOGOUT = 'LOGOUT';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const ADMIN_USERS_SUCCESS = 'ADMIN_USERS_SUCCESS';
export const ADMIN_USERS_FAIL = 'ADMIN_USERS_FAIL';
export const ADMIN_USERS_INIT = 'ADMIN_USERS_INIT';
export const DELETE_ADMIN_USERS_SUCCESS = 'DELETE_ADMIN_USERS_SUCCESS';
export const DELETE_ADMIN_USERS_FAIL = 'DELETE_ADMIN_USERS_FAIL';
export const DELETE_ADMIN_USERS_INIT = 'DELETE_ADMIN_USERS_INIT';

export const ADMIN_CLIENTS_SUCCESS = 'ADMIN_CLIENTS_SUCCESS';
export const ADMIN_CLIENTS_FAIL = 'ADMIN_CLIENTS_FAIL';
export const ADMIN_CLIENTS_INIT = 'ADMIN_CLIENTS_INIT';
export const DELETE_ADMIN_CLIENTS_SUCCESS = 'DELETE_ADMIN_CLIENTS_SUCCESS';
export const DELETE_ADMIN_CLIENTS_FAIL = 'DELETE_ADMIN_CLIENTS_FAIL';
export const DELETE_ADMIN_CLIENTS_INIT = 'DELETE_ADMIN_CLIENTS_INIT';

export const ADMIN_CITIES_SUCCESS = 'ADMIN_CITIES_SUCCESS';
export const ADMIN_CITIES_FAIL = 'ADMIN_CITIES_FAIL';
export const ADMIN_CITIES_INIT = 'ADMIN_CITIES_INIT';
export const DELETE_ADMIN_CITIES_SUCCESS = 'DELETE_ADMIN_CITIES_SUCCESS';
export const DELETE_ADMIN_CITIES_FAIL = 'DELETE_ADMIN_CITIES_FAIL';
export const DELETE_ADMIN_CITIES_INIT = 'DELETE_ADMIN_CITIES_INIT';

export const ADMIN_FILIALS_SUCCESS = 'ADMIN_FILIALS_SUCCESS';
export const ADMIN_FILIALS_FAIL = 'ADMIN_FILIALS_FAIL';
export const ADMIN_FILIALS_INIT = 'ADMIN_FILIALS_INIT';
export const DELETE_ADMIN_FILIALS_SUCCESS = 'DELETE_ADMIN_FILIALS_SUCCESS';
export const DELETE_ADMIN_FILIALS_FAIL = 'DELETE_ADMIN_FILIALS_FAIL';
export const DELETE_ADMIN_FILIALS_INIT = 'DELETE_ADMIN_FILIALS_INIT';

export const ADMIN_ENTRIES_SUCCESS = 'ADMIN_ENTRIES_SUCCESS';
export const ADMIN_ENTRIES_FAIL = 'ADMIN_ENTRIES_FAIL';
export const ADMIN_ENTRIES_INIT = 'ADMIN_ENTRIES_INIT';

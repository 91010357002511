import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import flattenObject from "../../../components/flattenObject";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

function UpdateVisitSchedule({raw, open, handleClose, handleSuccess}) {
    const clone = Object.assign({}, flattenObject(raw));
    delete clone.created_at
    delete clone.updated_at
    const [input, setInput] = useState(clone)

    const handleSave = () => {
      handleSuccess(input)
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    const handleDateChange = (name) => (value) => {
      handleChange({target: {name, value}});
    };
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Изменить</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="dense"
              id="date"
              name="date"
              label="Дата"
              format="yyyy.MM.dd"
              value={input['date']}
              onChange={handleDateChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'Дата',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Название процедуры"
            type="text"
            fullWidth
            value={input['name']}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="comment"
            name="comment"
            label="Комментрарии"
            type="text"
            fullWidth
            value={input['comment']}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
             Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default UpdateVisitSchedule

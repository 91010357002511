import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

function Render ({ data, dataIndex, Update, handleSuccess }) {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            {open && (<Update
                    raw={data[dataIndex] || {}}
                    open={open}
                    handleSuccess={data => handleSuccess(data, () => setOpen(false))}
                    handleClose={() => setOpen(false)}
                />)}
            <IconButton
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <EditIcon/>
            </IconButton>
        </>
    );
}

function CustomBodyRenderLiteInner(props) {
    return dataIndex => (
        <Render dataIndex={dataIndex} {...props} />
    )
}

export default CustomBodyRenderLiteInner;

import { 
  REFRESH_SUCCESS,
  LOGIN_SUCCESS,
  SIGN_UP_SUCCESS,
  LOGOUT
} from '../actions/types';

const emptyState = {
  user: null,
  token: {
    accessToken: null,
    refreshToken: null,
  }
}
// todo
const localStoreState = localStorage['redux-store']
  ? JSON.parse(localStorage['redux-store']).auth
  : emptyState;

const initialState = localStoreState

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return emptyState
    case LOGIN_SUCCESS:
      return action.payload
    case SIGN_UP_SUCCESS:
      return action.payload
    case REFRESH_SUCCESS:
      return action.payload
    default:
      return state;
  }
}
import { combineReducers } from 'redux';
import auth from './authReducer';
import loader from './loaderReducer';
import error from './errorReducer';
import admin_users from './admin/usersReducer';
import admin_clients from './admin/clientsReducer';
import admin_filials from './admin/filialsReducer';
import admin_cities from './admin/citiesReducer';
import admin_entries from './admin/entriesReducer';

export default combineReducers({
    loader,
    error,
    auth,
    admin_users,
    admin_clients,
    admin_filials,
    admin_cities,
    admin_entries,
});

import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import CanvasDraw from "react-canvas-draw";
import CreateLocalizationFace from "./CreateLocalizationFace";
import UpdateLocalizationFace from "./UpdateLocalizationFace";
import CustomBodyRenderLiteInner from "../../../components/Datatables/CustomBodyRenderLiteInner";
import CustomToolbar from "../../../components/Datatables/CustomToolbar";
import textLabels from "../../../components/textLabels";
import face from './face.jpg';

const useStyles = makeStyles(() => ({
  layout: {
    width: '100%',
    position: 'relative',
  },
}));

function LocalizationFace({input, handleChange}) {
  const classes = useStyles();
  const data = input['localizationFace.records'];

  const columns = [
    { name: 'name', label: 'Название' },
    { name: 'comment', label: 'Примечание' },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: CustomBodyRenderLiteInner({
          data,
          Update: UpdateLocalizationFace,
          handleSuccess: (updateData, cb) => {
            handleChange({target: {name: 'localizationFace.records', value: input['localizationFace.records'].map(item => item.id === updateData.id ? updateData : item )}});
            cb()
          }
        })
      }
    },
  ]
  const base = {
    hasValue: true,
    name: "",
    comment: "",
  }
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'simple',
    onRowsDelete: (rowsDeleted, _newTableData) => {
      const indexes = rowsDeleted.data.map(index => index.dataIndex)
      const ids = data.filter((_item, key) => indexes.includes(key)).map(item => item.id)
      handleChange({target: {name: 'localizationFace.records', value: input['localizationFace.records'].filter(item => !ids.includes(item.id) )}});
    },
    customToolbar: () => (
      <CustomToolbar base={base} Create={CreateLocalizationFace} handleSuccess={(newData, cb) => {
        handleChange({target: {name: 'localizationFace.records', value: [...input['localizationFace.records'], newData]}});
        cb()
      }} />
    ),
    textLabels
  };

  const saveFace = (data) => {
    handleChange({target: {name: 'localizationFace.scheme', value: data.getSaveData()}});
  }

  const loadableCanvas = useRef(null);
  useEffect(() => {
    if (loadableCanvas && loadableCanvas.current && input['localizationFace.scheme']) {
      loadableCanvas.current.loadSaveData(input['localizationFace.scheme']);
    }
  }, [loadableCanvas]);

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <CanvasDraw
            onChange={saveFace} 
            brushColor="rgba(155,12,60,0.3)"
            imgSrc={face}
            canvasWidth={274}
            canvasHeight={450}
            ref={loadableCanvas}
          />
          <p>Наличие (с указанием локализации и выраженности)</p>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
      </main>
    </React.Fragment>
  );

}
export default LocalizationFace

import axios from 'axios';
import store from '../store';
import { refresh } from '../actions'

const client = {
    fetch: async (method, name, data, params) => {
        const response = await axios.request({
            method,
            url: `${process.env.REACT_APP_COMMON_SERVER_ADDRESS}/${name}`,
            data,
            params,
            timeout: 1000,
            headers: {
                "Authorization": `Bearer ${ store.getState().auth.token.accessToken }`,
                "Accept": "application/json",
            }
        });
        if (response.data.error && response.data.error.state === 401) {
            const oldToken = store.getState().auth.token.accessToken
            store.dispatch(refresh({ refreshToken: store.getState().auth.token.refreshToken }))
            let resolve
            const promise = new Promise((rs, rj) => {resolve = rs})
            const unsubscribe = store.subscribe(async () => {
                if (store.getState().auth.token.accessToken === oldToken) {
                    return
                }
                unsubscribe()
                const repeatResponse = await axios.request({
                    method,
                    url: `${process.env.REACT_APP_COMMON_SERVER_ADDRESS}/${name}`,
                    data,
                    timeout: 1000,
                    headers: {
                        "Authorization": `Bearer ${ store.getState().auth.token.accessToken }`,
                        "Accept": "application/json",
                    }
                });
                resolve(repeatResponse)
            });
            return promise
        }
        return Promise.resolve(response)
    }
} 

export default client
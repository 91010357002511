import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const store = createStore(rootReducer, compose(
    composeWithDevTools(applyMiddleware(thunk)),
  )
);

store.subscribe(() => {
  localStorage['redux-store'] = JSON.stringify({
    auth: store.getState().auth
  });
});

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
        const newRootReducer = require('./reducers').default
        store.replaceReducer(newRootReducer)
    })
}

export default store
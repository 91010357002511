import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import CreateVisitSchedule from "./CreateVisitSchedule";
import UpdateVisitSchedule from "./UpdateVisitSchedule";
import CustomBodyRenderLiteInner from "../../../components/Datatables/CustomBodyRenderLiteInner";
import CustomToolbar from "../../../components/Datatables/CustomToolbar";
import textLabels from "../../../components/textLabels";

const useStyles = makeStyles(() => ({
  layout: {
    width: '100%',
  },
}));

function VisitSchedule({input, handleChange}) {
  const classes = useStyles();
  const data = input.visitSchedule;

  const columns = [
    { name: 'date', label: 'Дата', options: {customBodyRender: (value, _tableMeta, _updateValue) => value.toString()} },
    { name: 'name', label: 'Название процедуры' },
    { name: 'comment', label: 'Комментрарии' },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: CustomBodyRenderLiteInner({
          data,
          Update: UpdateVisitSchedule,
          handleSuccess: (updateData, cb) => {
            handleChange({target: {name: 'visitSchedule', value: input.visitSchedule.map(item => item.id === updateData.id ? updateData : item )}});
            cb()
          }
        })
      }
    },
  ]
  const base = {
    date: new Date(),
    name: "",
    comment: "",
  }
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'simple',
    onRowsDelete: (rowsDeleted, _newTableData) => {
      const indexes = rowsDeleted.data.map(index => index.dataIndex)
      const ids = data.filter((_item, key) => indexes.includes(key)).map(item => item.id)
      handleChange({target: {name: 'visitSchedule', value: input.visitSchedule.filter(item => !ids.includes(item.id) )}});
    },
    customToolbar: () => (
      <CustomToolbar base={base} Create={CreateVisitSchedule} handleSuccess={(newData, cb) => {
        handleChange({target: {name: 'visitSchedule', value: [...input.visitSchedule, newData]}});
        cb()
      }} />
    ),
    textLabels
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
      </main>
    </React.Fragment>
  );

}
export default VisitSchedule

import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import flattenObject from "../../../components/flattenObject";
import { makeStyles } from '@material-ui/core/styles';
import { updateAdminClients } from '../../../actions'
import TabPanel from "./TabPanel";
import Questionnaire from "./Questionnaire";
import ClientHistory from "./ClientHistory";
import Conclusion from "./Conclusion";
import History from "./History";
import HomeCare from "./HomeCare";
import LocalizationFace from "./LocalizationFace";
import Photos from "./Photos";
import VisitSchedule from "./VisitSchedule";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
}));

function Update({raw, open, handleClose, handleSuccess}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loader = useSelector(state => state.loader.loader);
    const clone = Object.assign({}, flattenObject(raw));
    delete clone.created_at
    delete clone.updated_at
    const [input, setInput] = useState(clone)
    const [tabIndex, setTabIndex] = useState(0)

    const handleChangeTab = (event, newValue) => {
      setTabIndex(newValue)
    }

    const handleSave = () => {
      dispatch(updateAdminClients(input, {}, handleSuccess));
    }
    // todo валидация
    const handleChange = event => {
      setInput({...input, [event.target.name]: event.target.value});
    }
    return (
      <Dialog fullScreen={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{ raw.questionnaire.surname } { raw.questionnaire.name } { raw.questionnaire.patronymic }</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Tabs orientation="vertical" variant='scrollable' value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="Анкета" />
              <Tab label="Анамнез" />
              <Tab label="График&nbsp;посещения" />
              <Tab label="Домашний&nbsp;уход" />
              <Tab label="Локализация&nbsp;образований" />
              <Tab label="Заключение" />
              <Tab label="История&nbsp;клиента" />
              <Tab label="Фотографии" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Typography component="h1" variant="h4" align="center">Анкета</Typography>
              <Questionnaire input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Typography component="h1" variant="h4" align="center">Анамнез</Typography>
              <History input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Typography component="h1" variant="h4" align="center">График посещения</Typography>
              <VisitSchedule input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <Typography component="h1" variant="h4" align="center">Домашний уход</Typography>
              <HomeCare input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <Typography component="h1" variant="h4" align="center">Локализация образований</Typography>
              <LocalizationFace input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <Typography component="h1" variant="h4" align="center">Заключение</Typography>
              <Conclusion input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
              <Typography component="h1" variant="h4" align="center">История клиента</Typography>
              <ClientHistory input={input} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={7}>
              <Typography component="h1" variant="h4" align="center">Фотографии</Typography>
              <Photos input={input} handleChange={handleChange} />
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loader}>
             Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default Update

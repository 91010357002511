import {
  LOGOUT,
} from './types';
import { fetchGenerator } from '../utils/fetchGenerator';

export const logout = () => ({
    type: LOGOUT
});
export const login = fetchGenerator('LOGIN', 'auth/login', 'post')
export const refresh = fetchGenerator('REFRESH', 'auth/refresh', 'post')
export const signUp = fetchGenerator('SIGN_UP', 'auth/signup', 'post')

import {
  LOGOUT,
  ADMIN_CITIES_SUCCESS,
  ADMIN_FILIALS_SUCCESS,
  ADMIN_CLIENTS_SUCCESS,
  ADMIN_ENTRIES_SUCCESS,
} from '../../actions/types';

const initialState = {
  cities: {},
  clients: {},
  filials: {},
}

export default function entriesReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case ADMIN_CITIES_SUCCESS:
      return { ...state,
        cities: {
          ...state.cities,
          ...action.payload.items.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      }
    case ADMIN_FILIALS_SUCCESS:
      return { ...state,
        filials: {
          ...state.filials,
          ...action.payload.items.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      }
    case ADMIN_CLIENTS_SUCCESS:
      return { ...state,
        clients: {
          ...state.clients,
          ...action.payload.items.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      }
    case ADMIN_ENTRIES_SUCCESS:
      return { ...state,
        cities: {
          ...state.cities,
          ...action.payload.cities.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        },
        filials: {
          ...state.filials,
          ...action.payload.filials.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        },
      }
    default:
      return state;
  }
}